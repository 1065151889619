@import url('https://fonts.googleapis.com/css2?family=Silkscreen&display=swap');
@import url(https://fonts.googleapis.com/css?family=Roboto:500);
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee+Spice&family=Seymour+One&display=swap');
 body {
  font-family: 'Bebas Neue', sans-serif;
	 background: rgba(19, 20, 22 );
     color:rgba(240, 236, 236, 0.952);
}.Boutiques
h1{
 font-family: 'Silkscreen', cursive;
}
h3{
  font-size: 35px;
}
 * {
	 padding: 0;
	 margin: 0;
	 box-sizing: border-box;
}
 li {
	 list-style: none;
   width: 100%;
}
.thmid{
  background:none !important;
}
.caract{
  display: flex;
  border-radius: 3px;
  width: 100%;
  font-size: 16px;
  font-weight: 200;
  color: #ffffff;
  justify-content: space-around;
  margin-top: 0px;
  margin-bottom: 20px;
  height: 32px;
  background-color: #8383835c;
}
.footer {
  display: flex;
  background-color: #0000000d;
  padding: 20px;
  text-align: center;
  position: absolute;
  bottom: -35vh;
  width: 100%;
  justify-content: space-around;
  border-radius: 2px;
}
.pdp-container {
  height: 49%;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.pdp-containeur {
  width: 25%;
  height: 49%;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pdp-containeur::before {
  /* Add an optional border for the image */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #fff; /* Customize the border color and size */
  border-radius: 50%;
  box-sizing: border-box;
}
.pdp-container::before {
  /* Add an optional border for the image */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #fff; /* Customize the border color and size */
  border-radius: 50%;
  box-sizing: border-box;
}
.footerus{
  display: flex;
  background-color: #0000000d;
  padding: 20px;
  text-align: center;
  position: absolute;
  margin-top: 74px;
  margin-left: 215px;
  width: 78%;
  bottom: auto;
  justify-content: space-around;
  border-radius: 2px;
}

.language_par{
  display: flex;
}
 ul {   
  list-style: none;
  display: flex;
  font-size: 20px;
  flex-direction: column;
  align-items: center;
}
.condition_uti{
  font-weight: 400;
  font-size: 23px; 
}
.connexion {
    display: flex;
    justify-content: space-between;
    margin: 40px;
    margin-left: 90px;
    margin-right: 90px;
    align-items: flex-start;
}

.siteName{
    margin-top:45px;
}
 
.presentation{
    display: flex;
    margin: 60px;
    margin-top: 150px;
}

.text{
  margin-left: 25px;
  width: 85%;
}

p{
    margin-top:7px;
}

.picture>p{
    display: inline-flex;
}


.connexion>button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin: 20px auto;
}


/** button connnexion**/
#login-form {
    background-color: rgba(19, 20, 22);
    padding: 40px;
    border-radius: 10px;
    width: 500px;
    height: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 20px #000;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: Arial, sans-serif;
}
h2{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
#login-form div input {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    flex-basis: 100%;
    color:#000;
    font-size:1.2em;
    height: 40px;
}

 button[id="submit-btn"] {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin: 20px auto;
    border-radius: 5px;
}
button[id="submit-btn_co"] {
  background-color: #007bff;
  color: #fff;
  width: 55px;
  cursor: pointer;
  border: none;
  height: 34px;
  border-radius: 5px;
}

 button[id="password-help-btn"]{
    background-color: #ffc400;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin: 20px auto;
    border-radius: 5px;
    border-radius: 5px;
    margin-left: 85px;
}

button[id="password-paword-btn"]{
  background-color: rgba(220, 156, 22, 0.77);
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  border-radius: 5px;
  height: 34px;
}
button[id="password-paword-btn"]:hover{
  height: 34px;
  background-color: rgba(165, 69, 5, 0.77);
  transition: ease 1s;
  box-shadow: 2px 3px 4px 0 rgb(255 255 255 / 25%);
}

button[id="submit-btn_co"]:hover{
  background-color: rgba(0, 255, 234, 0.77);
  transition: ease 1s;
  box-shadow: 2px 3px 4px 0 rgb(255 255 255 / 25%);
}


/** finnn */

/* Style de base du sidebar */
#sidebar>ul{
  padding-left: 7px;
  font-size: 10px;
  min-height: 829px;
}
#sidebar {
  position: absolute;
  top: 0;
  width: 200px;
  height: auto;
  padding-bottom: 100px;
  left: 0;
  z-index: 5;
  background-color: none;
  color: #fff;
  transition: all 0.5s ease;
}
.navinteract{
  color: white;
  font-weight: bold;
  position: absolute;
  top: 105px;
  border: none;
  padding: 2px;
  border-radius: 8px;
  cursor: pointer;
  background: none;
}
    
    /* Style pour le bouton d'ouverture/fermeture du sidebar */
    #sidebar-toggle-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    }

    /* Style pour les éléments de navigation du sidebar */
    #sidebar a {
      display: flex;
      background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
      padding: 17px;
      margin-bottom: 7px;
      color: #fff;
      border-radius: 9px;
      font-size: 18px;
      text-decoration: none;
      transition: background-color 0.3s ease-out;
      flex-direction: row;
    }
    
    /* Style pour les éléments de navigation du sidebar lorsqu'ils sont survolés */
    #sidebar a:hover {
      background: rgb(255,0,153);
      background: linear-gradient(311deg, rgba(255,0,153,1) 0%, rgba(48,35,174,1) 100%);
    }
    
    /* Style pour le contenu de la page */
    #main-content {
    margin-left: 250px}

    .Bazar {
      margin-top: 70px;
      min-width: 1501px;
    position: relative;
    }

    .Bazar>h1{
      position: absolute;
      left: 49%;
      font-size: 30px;
      margin-left: 25px;
      margin-top: -50px;
    }
    .Bazar>.esti{
      left: 50.2%;

    }
    .info_jeux {
      display: flex;
      justify-content: space-evenly;
      margin-top: 55px;
      font-size: 10px;
      z-index: -1;
      margin-left: 140px;
    }
    .info_jeux>.css-1xpgit6-container{
      width: 120px;
      font-size: 15px;
    }

    table{
      width: 85%;
      margin-top: 107px;
      border-radius: 5px;
      min-width: 1000px;
      font-size: 20px;
      margin-left: 235px;
    }

ul>h1{
  display: flex;
  margin-top: 24px;
  margin-bottom: 45px;
  font-size: 45px;
  align-items: baseline;
}

img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 3px;
}

.messagepri{
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
}
.pseudo_nav_btn{
  display: flex;
  flex-direction: column;
  height: 55px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  border-radius: 3px;
  background: #faebd700;
  box-shadow: 0px 0px 35px 0px #000000;
}

.pseudo_nav_btn:hover{
  background-color:rgba(126,80,255,0.17);
}


.vendre{
        margin-left:250px;
        margin-top:25px;
}
.vendre>h1{
    position: absolute;
    left:80%;
}

.Bazar>form>input{
    border-radius: 4px;
}

select {
    width: 140px;
    height: 35px;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 2px 2px 8px #999;
    background: #eee;
    border: none;
    outline: none;
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
  }

  label:after {
    font: 11px "Consolas", monospace;
    color: #666;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 8px;
    top: 2px;
    padding: 0 0 2px;
    border-bottom: 1px solid #ddd;
    position: absolute;
    pointer-events: none;
  }
  label:before {
    right: 6px;
    top: 0px;
    width: 20px;
    height: 20px;
    background: #eee;
    position: absolute;
    pointer-events: none;
    display: block;
  }

option{
    background-color: #333;
    color: white;
}
select.selected {
    background-color: rgba(91, 217, 6, 0.8);
    color:white;
  }     


.Navlink>.material-symbols-outlined {
  padding-right: 10px;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
.material-symbols-outlined {
  padding-left: 7px;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.vente {
  display: flex;
  margin-top: 200px;
  position: absolute;
  left: 30%;
  }

.vente .card_left {
  margin-right: 90px;
  display: flex;
  margin-left: 5%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 350px;
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  height: 420px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  .vente .card_right {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width:350px;
    height: 420px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
margin-bottom: 10px;
background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
    margin-left: 2.5%;
    align-items: center;
    justify-content: space-evenly;
  }

  .vente .card_left input{
    width:auto;
  }

  .card_right div{
    display: flex;
    justify-content: space-around;
  }
  .card_right div input{
    width:20%;
  }
  .upeur{
    width: 55px !important;
    text-align: center;
    border: none;
    outline: none;
    color: black;
    border-radius: 15px;
    padding: 5px;
    background-color: #ccc;
    box-shadow: inset 2px 5px 10px rgba(0,0,0,0.3);
    transition: 300ms ease-in-out;
  }
.submit_msgsel{
  color:white;
  height: 30px;
  border-radius: 2px;
  border: none;
  width: 60px;
  background-color:#007bff;
}
.amelioeur{
  width: 55px !important;
  text-align: center;
  border: none;
  outline: none;
  color: black;
  border-radius: 15px;
  padding: 5px;
  background-color: #ccc;
  box-shadow: inset 2px 5px 10px rgba(0,0,0,0.3);
  transition: 300ms ease-in-out;
}
.Rarity{
  width: 55px !important;
  text-align: center;
  border: none;
  outline: none;
  color: black;
  border-radius: 15px;
  padding: 5px;
  background-color: #ccc;
  box-shadow: inset 2px 5px 10px rgba(0,0,0,0.3);
  transition: 300ms ease-in-out;
}
.innputor>input:focus {
  background-color: white;
  transform: scale(1.05);
  box-shadow:0px 0px 20px #969696, 0px 0px 50px #ffffff5b
}
.inpu_esti>input:focus {
  background-color: white;
  transform: scale(1.05);
  box-shadow:0px 0px 20px #969696, 0px 0px 50px #ffffff5b
}
.amelioeur:focus {
  background-color: white;
  transform: scale(1.05);
  box-shadow: 0px 0px 20px #969696, 0px 0px 50px #ffffff5b
}
.upeur:focus {
  background-color: white;
  transform: scale(1.05);
  box-shadow:0px 0px 20px #969696, 0px 0px 50px #ffffff5b
}
.Rarity:focus {
  background-color: white;
  transform: scale(1.05);
  box-shadow: 0px 0px 20px #969696, 0px 0px 50px #ffffff5b
}
.quantitu:focus {
  background-color: white;
  transform: scale(1.05);
  box-shadow: 0px 0px 20px #969696, 0px 0px 50px #ffffff5b
}
.modal-content_sell button {
  width: 340px;
  height: 40px;
  margin-top: 25px;
  background-color:#007bff;
  cursor: pointer;
  border-radius: 10px;
  color:white;
  border:none;

}
#crosshair::before {
    transform: rotate(90deg);
}


.list-carac>ul{
  font-size: 18px;
  display: block;
  margin-bottom: 17px;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hidden {
  display: none;
}

#mire {
  position: absolute;
  transform: translate(-50%, -50%);
}
.Language_insc{
  display: flex;
  justify-content: center;
}
.sous-cat{
  display: flex;
    width: 601px;
    justify-content: space-between;
}
#overlay_mp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}


/* buttonn envoyer mp */
.myButton {
	box-shadow:inset 0px 1px 0px 0px #a4e271;
	background:linear-gradient(to bottom, #89c403 5%, #77a809 100%);
	background-color:#89c403;
	border-radius:6px;
	border:1px solid #74b807;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:14px;
	font-weight:bold;
	padding:8px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #528009;
}
.myButton:hover {
	background:linear-gradient(to bottom, #77a809 5%, #89c403 100%);
	background-color:#77a809;
}
.myButton:active {
	position:relative;
	top:1px;
}

/* finn button envoyer*/
.propose-ritem{
    position: absolute;
    width: -webkit-fill-available;
    display: flex;
    margin-left: 30px;
    left: 250px;
    margin-top: 50px;
    justify-content: space-around;}

.propose-item{
  position: absolute;
  width: -webkit-fill-available;
  display: flex;
  margin-left: 0px;
  left: 200px;
  margin-top: 50px;
  justify-content: space-around;
}
.item2{
  margin-top: 98px;
}
.propose-item>button{
  background: none;
  border: none;
}
.Profilus{
  box-shadow: 0px 0px 35px 0px #000000;
  display: flex;
  position: absolute;
  height: 80px;
  background: rgb(19 20 22 / 0%);
  width: 99.4%;
  border-radius: 3px;
  margin-top:7px;
}
.Profil{
  box-shadow: 0px 0px 35px 0px #000000;
  display: flex;
  position: fixed;
  height: 100px;
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  bottom: -1px;
  width: 200px;
  border-radius: 9px;
  margin-top: 7px;
}
.img_pro{
  margin-top: 21px;
  margin-left: 5px;
  margin-right: 10px;
}

.info_player{
  font-size: 15px;
  font-weight: 500;
}

#sidebar .co{
  position: static;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  z-index: 4;
  text-align: center;
}



.co > .sig_btn{
  width: 208px;
  margin: 8px;
  border: none;
  height: 44px;
  font-size: 20px;
  border-radius: 4px;
}


.sig_btn{
  color: white;
  background-color:  rgba(220, 156, 22, 0.77);
}
.sig_btn:hover{
  background-color:  rgba(153, 106, 5, 0.77);
  cursor: pointer;
  transition: ease 1s;
}
#sidebar .sig_btn {
  padding: 3px;
}



.Form_inscri{
  position: absolute;
  display: flex;
  left: 47%;
}
.Iart_form{
display: flex;
    flex-direction: column;
    margin-top: 130px;
}

.Iart_form input{
  margin-top: 5px;
  margin-bottom: 15px;
  margin-left:10px;
}
.Inscriptions{
margin-left:-255px;
}

.Irese{
  margin-top: 30px;
}

.Iart_form> button{
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
}

.Modal_all {
  position: fixed;
  z-index: 3;
  top: 0;
  width: 100%;
  height: 100%;
}

.Overlay{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}
.custom-checkbox .form-check-input {
  transform: scale(1.2); /* Agrandit légèrement la case à cocher */
  margin-right: 10px; /* Ajoute une marge à droite pour l'éloigner du texte */
}

/* CSS pour l'étiquette de la case à cocher */
.custom-checkbox .form-check-label {
  margin-left: 5px; /* Ajoute une marge à gauche pour l'éloigner de la case à cocher */
}
.form-check-label{
  font-size: 15px;
}
.Signup_Modal{
  width: 880px;
  position: absolute;
  box-shadow: 5px 5px 36px 5px #000000;
  border-radius: 8px;
  height: auto;
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  padding: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50% ,-50%);
}
.Signin_Modal{
  width: 450px;
  position: absolute;
  box-shadow: 5px 5px 36px 5px #000000;
  border-radius: 8px;
  height: 420px;
  display: flex;
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  top: 50%;
  left: 50%;
  transform: translate(-50% ,-50%);
  justify-content: center;
}
.text-danger{
  color: red;

  font-size: 15px
}

.frgt_pswd{
  display: flex;
    flex-direction: column;
    align-items: center;
}
.Signup_Modalus{
  width: 415px;
  position: absolute;
  text-align: center;
  box-shadow: 5px 5px 36px 5px #000000;
  border-radius: 8px;
  height: 300px;
  background-color: rgb(12 11 11);
  /* border: 2px outset rgb(104 102 92 / 72%); */
  padding: 15px;
  top: 50%;
  display: flex;
  left: 50%;
  transform: translate(-50% ,-50%);
  flex-direction: column;
  justify-content: flex-start;
}

.form-groupu{
  margin-bottom: 30px;
}

.sign-up-form{
  display: flex;
  margin-top: 20px;
  font-size: 10px;
  margin-left: 0;
  flex-direction: column;
  align-items: center;
}

.mb-3{
  margin-top: 10px;
  margin-bottom: 35px;
}
.label_form:before{
  content: "";
  right: 0;
  top: 0px;
  width: auto;
  height: auto;
  position: block;
  pointer-events: none;
  display: block;
}


.label_form:after{
content: "";
font: none;
-webkit-transform: none;
-moz-transform: none;
-ms-transform: none;
transform: none;
right: 0;
top: 0;
padding: 0 0 0;
border-bottom: none;
position: absolute;
pointer-events: none;
}

.label_form{
  color: white;
}


.btn-close{
  background: none;
    margin-left: 82px;
    color: rgba(108, 5, 5, 0.84);
    border: none;
}

.header{
  font-size: 30px;
  display: flex;
  margin-bottom: 50px;
  margin-top: 15px;
  justify-content: center;
}
.signuppp{
  width: 60%;
  display: flex;
  margin-top: 20px;
  
  max-height: 40px;
  margin-bottom: 15px;
  justify-content: space-between;
}
.signuppp>.btn-primary{
  width: auto;
  border: none;
    height: 40px;
  padding-top:2px;
  padding-bottom:2px;
  padding-left:20px;
  padding-right:20px;
  margin-bottom: 15px;
}
.conditionq{
  font-size: 18px;
  height: 130px;
  border-radius: 7px;
  border: 1px solid;
  margin: 10px;
  overflow-y: scroll;
}
.texte-danger{
  color:red;
  text-align: center;
  font-size: 15px;
  margin-top:15px;
}

.Logout{
  float: right;
  position: absolute;
  bottom: 0;
  margin-right: 5px;
  right: 0;
}

.Logout>button{
  color: #eb4d4da3;
  background: none;
  margin-bottom: 3px;
  border: solid 2px #eb4d4da3;
  border-radius: 4px;
}
.Logout>button:hover{
  cursor: pointer;
}
.free_ad{
  cursor: pointer;

} 
.material-symbols-outlined {
  position: relative;
  display: inline-block;
}

.free_ad_text {
  position: absolute;
  top: -1.25em; /* Ajustez cette valeur selon vos besoins pour positionner le texte */
  left: -10px;
  
  font-family: 'Bebas Neue', sans-serif;
  display: none;
}

.material-symbols-outlined:hover .free_ad_text {
  display: inline;
}
.Navlink li .Navlink{
  display: flex;
  flex-direction: column;
  align-items: center;
} 

.sidebar .Navlink{
  display: flex;
  align-items: center;
  font-size: 12px;
}

.Picture_profil{
  border: 2px solid #fff;
  border-radius: 50%;
  margin-left: 7px;
  max-width: 60px;
  max-height: 60px;
}

.google-btn {
  width: 200px;
  height: 50px;
  background-color: #007bff;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
}
.sign_ggool{
  font-weight: 100;

}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 0.3px;
  margin-left: 1px;
  width: 47px;
  height: 49.4px;
  margin-left: 1px;
  border-radius: 6px;
  background-color: #fff;
  }

.google-btn .google-icon {
  border: none;
  margin-top: 6.800000000000001px;
  margin-left: 6px;
  width: 75%;
  }
  .google-btn .btn-text {
    margin: 14px 8px 0 0;
    color: #fff;
    font-size: 15px;
    margin-left: 55px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  .close_btn:hover{
    cursor: pointer;
  }
  .google-btn:hover {
    box-shadow: 0 0 6px #4285f4;
  }

  .google-btn :active {
    background: #1669F2;
  }

  .material-symbols-outlined:hover{
    filter: brightness(1.75);
  }


  .settings{
    margin-top: 35px;
    margin-left: 170px;
    display: flex;
    position: sticky;
    flex-direction: column;
    font-size: 25px;
  }

  .Info_player{
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
    margin-right: 25px;
    align-items: center;
  }

  .img_player{

    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

  .confirm_rune{
    position: absolute;
    height: 70%;
    width: 75px;
    font-size: 20px;
    color: red;
    background: #00000085;
    text-align: center;
    display: none;
    left: 47%;
    justify-content: center;
    align-items: center;
  }
  .Com_player{
    background: radial-gradient(circle at 100% 100%, #131416 0, #131416 5px, transparent 5px) 0% 0%/7px 7px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 5px, transparent 5px) 100% 0%/7px 7px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 5px, transparent 5px) 0% 100%/7px 7px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 5px, transparent 5px) 100% 100%/7px 7px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 4px) calc(100% - 14px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 14px) calc(100% - 4px) no-repeat, linear-gradient(146deg, #ff0099 0%, #3023AE 100%);
    width: 70%;
    box-shadow: 0px 0px 35px 0px #000000;
    height: 137px;
    text-align: center;
    color: white;
    border-radius: 4px;
    border:none;
  }
  .rapbug{
    color:red;
  }
  .Commantère{
    display: flex;
    margin-top: 49px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
  }
  .mail_verif{
    font-size: 20px;
    color: #00ff39bf;
  }

  .gif_note{
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 32px;
    justify-content: space-evenly;
  }
  .new_accou{
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: space-around;
  }
  .img_new{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .text_new{
    display: flex;
    font-size: 18px;
    font-weight: 500;
    width: 75%;
    flex-direction: column;
    align-items: center;
  }
  .real_img_player{
    max-width: 123px;
    border-radius: 0;
    border: none;
    padding: 2px;
    max-height: 75px;
  }
  .real_img_playeur{
    max-width: 123px;
    border-radius: 0;
    border: none;
    padding: 2px;
    max-height: 55px;
  }
  .Commantère>button{
    background: none;
    border: none;
    position: sticky;
    bottom: 33px;
  }
  .Commantère>button>.material-symbols-outlined{
  color: rgba(54, 204, 16, 0.747);
  }
  .uploa_gif{
    display: flex;
    align-items: baseline;
    justify-content: center;
  }
  .gradient{
    margin-top: 150px;
    height: 2px;
    width: 60%;
    background:radial-gradient(circle at 100% 100%, #131416 0, #131416 5px, transparent 5px) 0% 0%/7px 7px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 5px, transparent 5px) 100% 0%/7px 7px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 5px, transparent 5px) 0% 100%/7px 7px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 5px, transparent 5px) 100% 100%/7px 7px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 4px) calc(100% - 14px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 14px) calc(100% - 4px) no-repeat, linear-gradient(146deg, #ff0099 0%, #3023AE 100%);
  }
.caradroit{
  display: flex;
    width: 300px;
    flex-direction: column;
}
.img_sp{
  height: 20px;
  margin-right: 5px;
  margin-left: 5px;
}
  .gradientus{
    margin-top: 51px;
    height: 2px;
    width: 60%;
    background:radial-gradient(circle at 100% 100%, #131416 0, #131416 5px, transparent 5px) 0% 0%/7px 7px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 5px, transparent 5px) 100% 0%/7px 7px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 5px, transparent 5px) 0% 100%/7px 7px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 5px, transparent 5px) 100% 100%/7px 7px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 4px) calc(100% - 14px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 14px) calc(100% - 4px) no-repeat, linear-gradient(146deg, #ff0099 0%, #3023AE 100%);
  }

.pseudl{
  display: flex;
  justify-content: space-around;
}
.discrd{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;
}

.email_user{
  display: flex;
  align-items: flex-start;
}
.email_mdp{
  display: flex;
}


  .utilisateur_param{
    display: flex;
    margin-top: 1.8%;

    flex-direction: row;
    justify-content: space-evenly;
  }
  .top{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-left: 40px;
    flex-direction: column;
    width: auto;
  }

  .bottom{
    display: flex;
    margin-top: 20px;
    width: 90%;
  }

  .setting_form{
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    font-size: 0px;
    margin-left: 260px;
    flex-direction: column;
    font-size: 15px;
    align-items: flex-end;
  }

.formInput label{
  position: initial;
}

.setting_form .formInput{
  margin-top:10px;
  margin-left:10px;
}

.tableau{
  margin-left:250px;
}

.Geestionn_title{
  font-size: 40px;
  left: 849px;
  z-index: -2;
  margin-top: -95px;
  position: absolute;
}

.co{
  z-index: 2;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  bottom: 0;
  background-color: rgb(0 0 0 / 73%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_advert{
  display: flex;
  border: 1px solid #555555;
  width: 450px;
  height: 221px;
  border-radius: 0.5rem;
  background: #000000;
  text-align: center;
  color: orange;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.close_advertt{
  color: orange;
  position: absolute;
    top: 36%;
  cursor: pointer;
}
.clasadvert:hover{
  color:rgb(255, 196, 0);
}
.modal {
  display: flex;
  padding: 1rem;
  height: auto;
  width: 715px;
  border-radius: 0.5rem;
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  align-items: center;
}

.Caractéristique {
  max-height: 541px;
  height: 480px;
  display: flex;
  width: 300px;
  justify-content: center;
}
#close-crosshair-button{
  background: none;
  border: none;
  height: 25px;
  top: -30px;
  color: red;
  left: 250px;
  position: relative;
}

.btn_back_home {
  background-color: rgba(255, 255, 255, 0);
  color: rgb(255, 255, 255);
  width: 150px;
    border-radius: 5px;
    height: 30px;
  transition-duration: 0.4s;
  border: 2px solid #4CAF50; /* Green */
}
.btn_back_home:hover {
  background-color: #4CAF50; /* Green */
  color: white;
}
#close-crosshair-button2{
  position: relative;
  bottom: 232px;
  left: 314px;
  background: none;
  border: none;
  color: red;
}
#close-crosshair-button2:hover{
  cursor: pointer;
}
.TitleSell{
  margin-bottom: 20px;
  margin-top: -5px;
}
.props_esti{
  display: flex;
  justify-content: space-evenly;
}

.esti_up{
  color: green;
}
.esti_down{
  color:red;
}
.esti{
  background:none;
  border: none;
  margin-top:5px
}

.Esti{
  background: #1ebb02;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  color: #fff;
  height: 25px;
  left: 265px;
  position: relative;
  top: 0px;
  width: 55px;
}

.displayNamedd{
  position: inherit;
}
.modaleur{
 justify-content: center;
 z-index: 2;
 width: auto;
}
.overlayeur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-overlay>.modaleur>form>button{
  border:none;
  width: auto;
  margin-left: 22px;
  height: 25px;
  background-color: #007bff;
  cursor: pointer;
  border-radius: 3px;
  font-size: 12px;
  color: white;
}


.Gestion_Sell{
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  border-radius: 9px;
  box-sizing: content-box;
  margin-left: -30px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  width: 100%;
  min-width: 1000px;
}


tbody tr:nth-child(even) {
  background-color: #3123ae2b;
}

.modify{
  background: none;
  border: none;
  color: orange;
  margin-left:5px;
  margin-top:2px;
}

.Accept{
  background: none;
  border: none;
  color: green;
}
.Canc{
  background: none;
  border: none;
  color: red;
}

.price{
  display: flex;
  justify-content: center;
  align-items:center;
}

.quantity>div {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#change-price-input{
  width: 60px;
  text-align: center;
  margin-right: 5px;
  border: none;
  background: #9d9d9d57;
  color: white;
  border-radius: 3px;
}


.modal_msg{
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  z-index: 2;
  padding: 15px;
  width: 560px;
  display: flex;
  height: 150px;
  align-items: center;
  border-radius: 9px;
  justify-content: space-evenly;
  flex-direction: column;
}

.modal-overlay>.modal_msg>#close-crosshair-button{
  position: relative;
  bottom: -1px;
  left: -29px;
  background: none;
  border: none;
  color: red;
}


h1 span:nth-child(1) {
  color: red;
}

h1 span:nth-child(2) {
  color: orange;
}

h1 span:nth-child(3) {
  color: yellow;
}

h1 span:nth-child(4) {
  color: green;
}

h1 span:nth-child(5) {
  color: blue;
}



 
.nbversion {
  font-size:50%;
  padding:0;
}
.modal_del{
  position: fixed;
  top: 50%;
  border-radius: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 25;
  background-color: #adab086c;
}
.language_web{
  margin-right: 50px;
  top: 51px;
  display: flex;
  font-size: 20px;
  align-items: center;
  flex-direction: column;
}
.flag_language{
  font-size: 50px;
  display: flex;
  margin-bottom: 9px;
}
div>button>img{
  padding:2px
    }
  div>button>img:hover{
  cursor: pointer;
  padding:0;
  height: 40px;
  width: 40px;
  }
.Sur_Delet {
  position: fixed;
  border-radius: 18px;
  padding: 10px;
  font-size: 20px;
  color: #ff0000;
  border: 3px outset #463c3875;
  transform: translate(-50%, -50%);
  z-index: 16;
  box-shadow: 2px 2px 8px #464545;
  height: auto;
  background: #1c1c19;
  width: 450px;
  font-weight: 450;
}
.check_paypal{
  display: none;
}
.purshace{
  font-size: 35px;
    border: solid 1px;
    color: #3bf900;
    border-radius: 6px;
    padding: 5px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
}
.purshace:hover{
  font-size: 38px;
    border: solid 1px;
    color: rgba(60, 255, 0, 0.486);
    border-radius: 6px;
    padding: 5px;
}
.text_danger{
  font-weight: 100;
    color: #e1923e;
    font-size: 20px;
}
.Sur_Delet>button{
    font-weight: bold;
    border: none;
    margin: 47px;
    height: 60px;
    font-size: 15px;
    width: 90px;
}
.sup_doc {
  background: #ffb224c7;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 3px;
}
.sup_doc:hover {
  color: rgb(182, 109, 0);

  background-color: hsla(28, 100%, 56%, 0.45);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.annul_doc{
  color: white;
  background-color:rgb(72 98 233) ;
  height: 25px;
  border-radius: 3px;
}
.annul_doc:hover{
  color: rgb(201 201 201);
  height: 60px;
  background-color: hsl(195.92deg 100% 50% / 87%);

  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.card_righteur{   
  display: flex;
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  flex-direction: column;
  width: 350px;
  height: auto;
  border-radius: 9px;
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  margin-bottom: 10px;
  margin-left: 2.5%;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 30px;
}

.input_1{
  width: 50px;
  text-align: center;
  height: 25px;
  padding-top: 1px;
  background: #9d9d9d57;
  border: none;
  border-radius: 4px;
  color: white;
  border-radius: 0 5px 5px 0;
}

#file_sell{
  font-size: 10px;
  margin-top: 14px;
}

.sumbit_img {
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 10px;
  padding: 0px 0px 9px 22px;
  border: none;
  overflow: hidden;
  background: rgb(164, 46, 194);
  color: #fff;
}

.sumbit_img {
  transform: translateX(15px);
  font-size: 15px;
  font-weight: 700;
  transition-duration: 0.3s;
}


.iconer {
  transform: translateY(35px);
  transition-duration: 0.3s;
}

.sumbit_img:hover .button-text {
  transform: translateX(0px);
}

.sumbit_img:hover .iconer {
  transform: translateY(0px);
}

.file_up{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.select-add{
  margin-top:15px;
  margin-bottom:15px;
  height:24.5px;
  width: 175px;
}

.Titre_select{
  display: flex;
  margin-left: 138px;
  margin-top:15px;
}

.Titre_select>button{
  background: none;
  color:rgb(0, 140, 255);
  border:none;
  margin-left: 50px;
}
.runes{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Titre_selection{
  display: flex;
    margin-left: 138px;
    margin-top: 15px;
    flex-direction: column;
}

.no_idea {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  align-items: center;
  justify-content: space-between;
}

.runes>.Add_select{
  background: none;
  border:none;
}

.ABC_rune>.Add_select{
color: goldenrod;
}

.S_rune>.Add_select{
  color: gold ;
}

.Spé_rune>.Add_select{
  color: greenyellow ;
}

.Spé_Pvp>.Add_select{
  color: rgb(182, 25, 182);  
}
.runes>.Add_select{
  background: none;
  border:none;
}

.ABC_rune{
color: goldenrod;
}

.S_rune{
  color: gold ;
}

.Spé_rune{
  color: greenyellow ;
}

.Spé_Pvp{
  color: rgb(182, 25, 182);  
}

.title_part{
  display: flex;
    align-items: center;
    font-size: 15px;
    margin-right: 10px;
}
.Haut_select  {
  margin-left: 0px;
}
.Titre_selecto{
  display: flex;
  margin-left: -51px;
}

.input_2{
  height: 35px;
    margin-left: -5px;
    width: 30%;
    border: none;
    border-radius: 0 5px 5px 0;
}

.titr_rue{
  padding-top: 30px;
  margin-bottom: 20px;
}

.div_selectadd{
  margin-left: 45px;
}

fieldset{
  display: flex;
  border: none;
}

.radio_part input[type="radio"] {
  width: 0;
  height: 0;
  opacity: 0;
}
.radio_sell {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3px;
  font-size: 15px;
  margin-left: 0px;
  border: solid 0.5px white;
  border-radius: 5px;
  width: 200px;
  height: 70px;
  justify-content: center;
}
.radio_sell input[type="checkbox"] {
  display: none; /* Hide the checkbox */
}
.radio_sell label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
}
.radio_sell input[type="checkbox"]:checked + label {
  background-color:  rgb(3, 230, 34);
  color: #333;
  border: none;
  width: 100%;
  height: 100%;
  transition: ease 1s;
  font-weight: bold;
  -webkit-box-shadow: 0px 10px 13px -7px #ffffff, 5px 5px 15px 5px rgba(0,0,0,0); 
box-shadow: 0px 10px 13px -7px #ffffff, 5px 5px 15px 5px rgba(0,0,0,0);
}
.radio_part label {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
}

.noneSkill{
  border:solid 1px red;
  border-radius: 3px;
}
.DSkill{
  border:solid 1px red;
  border-radius: 3px;
}
.CSkill{
  border:solid 1px darkorange;
  border-radius: 3px;
}
.BSkill{
  border:solid 1px #58bb7a;
  border-radius: 3px;
}
.ASkill{
  border:solid 1px green;
  border-radius: 3px;
}
.SSkill{
  border:solid 1px gold;
  border-radius: 3px;
}


.radio_part input[type="radio"][value="none"]:hover + label{
  background-color: red;
  transition: ease 0.5s;
  color: white;
}
.radio_part input[type="radio"][value="D"]:hover + label{
  background-color: red;
  transition: ease 0.5s;
  color: white;
}
.radio_part input[type="radio"][value="C"]:hover + label{
  background-color: darkorange;
  transition: ease 0.5s;
  color: white;
}
.radio_part input[type="radio"][value="B"]:hover + label{
  background-color: #58bb7a;
  transition: ease 0.5s;
  color: white;
}
.radio_part input[type="radio"][value="A"]:hover + label{
  background-color: green;
  transition: ease 0.5s;
  color: white;
}
.radio_part input[type="radio"][value="S"]:hover + label{
  background-color: gold;
  transition: ease 0.5s;
  color: white;
}

.radio_part input[type="radio"][value="none"]:checked + label {
  background-color: red;
  color: #333;
  border:none;
  width: 43px;
  height: 43px;
  transition: ease 1s;
  font-weight: bold;
}

.radio_part input[type="radio"][value="D"]:checked + label {
  background-color: red;
  color: #333;
  border:none;
    width: 43px;
  height: 43px;
  transition: ease 1s;
  font-weight: bold;
}

.radio_part input[type="radio"][value="C"]:checked + label {
  background-color: darkorange;
  color: #333;
  border:none;
    width: 43px;
  height: 43px;
  transition: ease 1s;
  font-weight: bold;
}

.radio_part input[type="radio"][value="B"]:checked + label {
  background-color: #58bb7a;
  color: #333; /* Adjust text color for visibility */
  border:none;
    width: 43px;
  height: 43px;
  transition: ease 1s;
  font-weight: bold;
}

.radio_part input[type="radio"][value="A"]:checked + label {
  background-color: green;
  color: #333;
  border:none;
    width: 43px;
  height: 43px;
    transition: ease 1s;
    font-weight: bold;
}

.radio_part input[type="radio"][value="S"]:checked + label {
  background-color: gold; 
  color: #333; /* Adjust text color for visibility */
  border:none;
    width: 43px;
  height: 43px;
    transition: ease 1s;
    font-weight: bold;
}

.radio_part input[type="radio"][value="S"]:checked + label {
  background-color: gold; 
  color: #333; /* Adjust text color for visibility */
  border:none;
    width: 43px;
  height: 43px;
    transition: ease 1s;
    font-weight: bold;
}



.card_right .radio_part input {
  width: 100%;
}

.msg-title{
  display: flex;
  margin-left: 240px;
  justify-content: center;
}

.pseudo_msg{
  width: 20%;
}
.date_msg {
  width: 20%;
}

.tr:hover{
  background-color: #707070b2;
}

.overlayo {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-color: transparent;
  transition: width 0.3s ease-in-out;
  z-index: 1;
}

.openo {
  right: 1348px;
  left: 444px;
  width: 122.5vh;
  top: 149px;
  background-color: rgba(0, 0, 0, 0.2);
}

.msg-details{
  position: absolute;
  border-radius: 3px;
  height: 77vh;
  left: 444px;
  width: 123.8vh;
  top: 148px;
  background-color: rgb(80 79 79);
}

.detailso {
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
  z-index: 2;
}

.openo .detailso {
  transform: translateX(-100%);
}

.msg_bas{
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 122vh;
}


.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}


.locked{
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 43%;
  height: 75%;
  top: 0;
  border-radius: 15px;
}



.retour_msg{
  background: none;
  color: rgb(138 46 46);
  border: none;
  z-index: 3;
}

.pseud_msg{
  width: 197.812px;
}

.Submit_msg{
    background: none;
    border: none;
    color: #4285f4;
}

.custom-file-upload{
  border:none;
}

.Boutique_title{
  display: flex;
  margin-top: 40px;
  margin-left: 149px;
  justify-content: center;
}
label > input[type="radio"] {
  display: none;
}
label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}
label > input[type="radio"]:checked + * {
  color: teal;
}
label > input[type="radio"]:checked + *::before {
  background: radial-gradient(teal 0%, teal 40%, transparent 50%, transparent);
  border-color: teal;
}
.Boutiques{
  top: 150px;
  min-width: 800px;
  width: 1261px;
  min-height: 400px;
  font-size: 24px;
  height: 600px;
  position: absolute;
  left: 20%;
  display: flex;
  z-index: -2;
  align-items: center;
  justify-content: space-around;
}

.boutique{
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  height: 414px;
  flex-direction: column;
  width: 20%;
  align-items: center;
  justify-content: space-around;
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
border-radius: 9px;
padding: 2px;
box-sizing: border-box;
}
.modal-content_sell{
  color: white;
  flex-direction: column;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  align-items: center;
  font-size: 15px;
  padding: 20px;
  background-color: rgb(12 11 11);
  border: 4px outset rgb(102, 102, 153);
  border-radius: 25px;
  text-align: center;
  position: absolute;
  top: 50px;
  left: 20%;
}
#modal_sell{
  top: 250px;
  left: 45%;
}
.code_point{
  z-index: 3;
  FONT-SIZE: 25px;
  padding: 20px;
  top: 700px;
  border-radius: 10px;
  text-align: center;
  position: absolute;
}
.pseudal_msg{
  position: absolute;
  top: 0;
  width: 100%;
  box-shadow: 2px 5px 37px 12px rgb(0 0 0 / 87%);
  border-radius: 3px;
}

.boutique>img{
  width: 100px;
    border: none;
}

.boutique>a{
  display: flex;
}

/* CSS */
.button-64 {
  align-items: center;
  background-image: linear-gradient(144deg,#00ddeb, #5b42f3 50%,#af40ff);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 25px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  margin: 15px 15px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-64:active,
.button-64:hover {
  outline: 0;
}

.button-64 span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button-64:hover span {
  background: none;
}

@media (min-width: 768px) {
  .button-64 {
    font-size: 0px;
    min-width: 170px;
    margin: 15px 15px;
  }
}

.picture_seller{
  max-height:490px;
  max-width: 330px;
  display: flex;
  min-width: 300px;

}

.ul_weapons{
  justify-content: space-around;
  height: 330px;
  text-shadow: 0 0 15px black;
}


.BienVendre{
  display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 125px;
    margin-top: 110px;
}

.li_vente{
  display: flex;
  margin-top: 23px;
}

.imag1_vente{
  width: 700px;
  border: none;
  margin-top: 5px;
}

.imag2_vente{
  width: 400px;
  border: none;
  margin-top: 5px;
}

.imag3_vente{
width: 100px;
    border: none;
    margin-left: 0px;
    margin-top: 123px;
}

.orpur{
  top: 157px;
  font-size: 15px;
  position: absolute;
}

.kk{
  position: absolute;
  top: 176px;
  font-size: 12px;
  right: 497px;
  z-index: 0;
}

.tit_ve{
  margin-bottom: 25px;
}

.imag4_vente{
  position: absolute;
  height: 88px;
  border: none;
  margin-top: 20px;
}

.imagfleche{
  position: absolute;
  border: none;
  width: 227px;
  z-index: 4;
  left: 46%;
  top: 376px;
}

.imagfleiche{
  position: absolute;
  border: none;
  width: 133px;
  z-index: 4;
  transform: rotate(180deg);
  left: 64%;
  top: 444px;
}
.down_capture{
  position: absolute;
  font-size: 25px;
  margin-top: 60px;
  left: 70%;
}

.Msg_modal{
  position: absolute;
    display: flex;
    margin-left: 200px;
    top: 63px;
    height: 85%;
    width: 80%;
}

.nav_pseu{
  width: 175px;
}

.pseudo_nav{
  cursor: pointer;
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 5px, transparent 5px) 0% 0%/7px 7px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 5px, transparent 5px) 100% 0%/7px 7px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 5px, transparent 5px) 0% 100%/7px 7px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 5px, transparent 5px) 100% 100%/7px 7px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 4px) calc(100% - 14px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 14px) calc(100% - 4px) no-repeat, linear-gradient(146deg, #ff0099 0%, #3023AE 100%);
  padding: 2px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 55px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 8px;
  margin-bottom: 8px;
  box-shadow: 0px 0px 35px 0px #000000;
}

.text_msg{
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(211deg, #ff0099 0%, #3023AE 100%);
  margin-left: 35px;
  border: 3px solid rgba(126,80,255,0.17);
  border-radius: 12px;
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 35px 0px #000000;
}

.input__msg {
  width: 94%;
  height: 50px;
  color: white;
  background: #4c4b4b;
  border-radius: 5px;
  flex: 1;
  padding-left: 10px;
}
.Petskill>ul{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn_msg{
  cursor: pointer;
  height: 50px;
  display: flex;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  width: 10%;
  align-items: center;
  justify-content: center;
}


.p_msg {
  font-weight: 50;
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  height: 30px;
  font-size: 15px;
  padding: 3px 15px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.message {
  display: flex;
  align-items: center;
  
  flex-direction: row-reverse;
  width: 100%;
  height: 50px; 
}


.sent {
  flex-direction: row-reverse;
  padding-right: 11px;
}

.sent p {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}
.countr_use{
  display: flex;
}
.picture_countr>svg{
  width: 40px;
}
.img_msg {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin: 2px 5px;
}

.BBas{
  min-height: 90%;
  position: relative;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
}
.form_mlsg{
  display: flex;
  position: sticky;
  flex-direction: row;
  bottom: 3px;
  left: 3px;
  width: 99.6%;
}
.pseudal_msssg{
  display: flex;
  background: #72727270;
  padding-top: 5px;
  justify-content: center;
  /* Assurez-vous que la classe pseudal_msssg occupe 100% de la largeur de son parent */
  width: 100%;
  /* Rendez la classe pseudal_msssg "sticky" pour la rendre toujours visible dans la div parent avec overflow */
  position: sticky;
  top: 0; /* Ou ajustez selon vos besoins */
  z-index: 1;
  margin-top: 0;
  height: 34px;
  font-size: 20px;
}
#root > div > div:nth-child(2) > div > div.text_msg > p > svg{
  margin-left: 18px;
  margin-top: 2px;
}
.butn_co{
  width: 120%;
  margin-top: 20px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.received{
  flex-direction: row;
}


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 7px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #2e80cc;
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.user_msg{
  display: flex;
  color: #9a9da1;
  font-weight: bold;
  margin-top: 7px;
  margin-left: 5px;
}

.Date_msg{
  margin-top: -3px;
  position: absolute;
  font-size: 8px;
  left: 129px;
}

.lastmsgg{
  margin-top: 5px;
  margin-left: 17px;
}

.received_msges{
  color: #fff;
  font-weight: bold;
}


.Discord_page{
  margin-left: 240px;
  display: flex;
  flex-direction: column;
}

.about-title{
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.discordUs{
  display: flex;
  width: 80%;
  margin-left: 280px;
  margin-top: 10%;
  align-items: center;
}

.widget-2vZvsN{
  font-size: 10px;
}

.pres_disc{
  text-align: center;
  line-height: 30px;
  padding: 20px;
  font-size: 25px;
}
.giff{
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.abrainn{
  border: none;
  height: 160px;
}
.abrainn2{
  border: none;
  height: 130px;
}

.title_esti{
  margin-left: 150px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

.btnn_esti{
  top: 90px;
  left: 140px;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.addpost{
  border: none;
    color: white;
    background: #59d143;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
  
}
.addpostus{
  border: none;
    color: white;
    background: #59d143;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    margin-left: 250px;
}
.addpostus:hover{
  cursor: pointer;
  background: #105f02;
  transition: ease 1s;
}

.btn_trade_sell:hover{
  cursor: pointer;
}
.modify:hover{
  cursor: pointer;
}
.addpost:hover{
  cursor: pointer;
  background: #105f02;
  transition: ease 1s;
}
.mypost{
  border: none;
    color: white;
    background: #007bff;
    padding: 10px;
    margin-top: 7px;
    border-radius: 5px;
    font-size: 14px;
}
.mypost:hover{
  background: #043468;
  cursor: pointer;
  transition: ease 1s;
}

.title_web {
  display: flex;
  margin-top: 16px;
  font-size: 45px;
  flex-direction: row;
  align-items: baseline;
}
.tophome{
  display: flex;
  margin-top: 35px;
    border: none;
    box-shadow: none;
    justify-content: space-around;
}

.body_home{
  display: flex;
  position: absolute;
  top: 89px;
  left: 2%;
  width: 94%;
}

.Presentations{
  position: relative;
  top: 55px;
  padding-right: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.cardus{
  margin-top: 70px;
  display: flex;
  justify-content: space-around;
}
.part1{
  height: 100%;
}
.carousel {
  position: relative;
  margin: 15px;
  box-shadow: 5px 5px 36px 5px #000000;
  border-radius: 15px;
}

.carousel img {
  display: block;
  margin-top: 15px;
  max-height: 450px;
  border: none;
}

.arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
}

.arrows button {
  background: transparent;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.arrows button:focus {
  outline: none;
}

.close_btn{
  border: none;
  background: none;
  right: 15px;
  color: red;
  top: 15px;
  position: absolute;
}
.form-control{
  margin: 5px;
    height: 25px;
}
._aces{
  top: 215px;
}

.innputor{
  display: flex;
  align-items: baseline;
}

.countr{
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.sumbit_imgee{
  width: 40px;
    height: 15px;
    margin-top: 0px;
    background-color: #007bff;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    border: none;
    font-size: 8px;
}

.inpu_img_pplayy{
  font-size: 5px;
}
.card_users{
  position: absolute;
  top: 80%;
  height: 150px;
  width: 480px;
  display: flex;
  border-radius: 8px;
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  left: 36%;
  justify-content: space-around;
  align-items: center;
}

.div_pict{
  display: flex;
  align-items: center;
  margin-right: 10px;
  top: 760px;
}

.btn_show{
  background-color: rgb(0 255 2 / 52%);
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 27px;
  font-size: 13px;
  padding: 11px;
  margin-bottom: 5px;
  margin-top: 5px; 
}
.gif_player{
  display: flex;
    flex-direction: column;
}

.real_gif_player{
  width: 540px;
  box-shadow: 0px 0px 35px 0px #000000;
  height: 260px;
  margin-top: 10px;
}

.Playerse{
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  }
  .RealPrice>p{
    margin-top: 0;
  }
.RealPrice{
  display: flex;
  height: 35px;
  text-shadow: 2px 2px 2px #7A7A7A;
  padding: 4px;
  border-radius: 3px;
  color: white;
  font-weight: 200;
  font-size: 17px;
  background: #b7ab15;
  align-items: center;  
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.Info_playeer{
      width: 90%;
    display: flex;
    margin-top: 20px;
    font-size: 25px;
    align-items: center;
    justify-content: space-around;

}
.sumbit_gif_player{
  width: 66px;
  height: 25px;
  margin-top: 0px;
  background-color: #007bff;
  cursor: pointer;
  border-radius: 5px;
  color: white;
  border: none;
  font-size: 12px;
}
.Info_playeer>svg{
  width: 50px;
    height: 28px;
}


.resultat_esti{
  position: absolute;
  width: 80%;
  min-height: 337px;
  overflow-y: auto;
  height: 70%;
}
.btn_tri{
  background: none;
  color: #61b5ff;
  border: none;
}
.PagePlayer {
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 1300px;
  min-height: 675px;
  border-radius: 10px;
}

.topeur{
  display: flex;
  align-items: center;
  margin-top: 13px;
  justify-content: center;
  flex-direction: column;
}

.Com_playeur{
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 5px, transparent 5px) 0% 0%/7px 7px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 5px, transparent 5px) 100% 0%/7px 7px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 5px, transparent 5px) 0% 100%/7px 7px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 5px, transparent 5px) 100% 100%/7px 7px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 4px) calc(100% - 14px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 14px) calc(100% - 4px) no-repeat, linear-gradient(146deg, #ff0099 0%, #3023AE 100%);
  width: 75%;
  display: flex;
  box-shadow: 0px 0px 35px 0px #000000;
  height: 137px;
  color: white;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}
.top>h1{
  margin-top:-30px;
}
.inpu_esti{
  display: flex;
  position: absolute;
  bottom: 15px;
  margin-left: 85px;
  align-items: baseline;
}
.inpu_esti>input{
  border: none;
  text-align: center;
  outline: none;
  border-radius: 15px;
  padding: 5px;
  background-color: #ccc;
  box-shadow: inset 2px 5px 10px rgba(0,0,0,0.3);
  transition: 300ms ease-in-out;
}
.btn_esti{
  background: none;
    color: #59d143;
    padding: 4px;
    padding-bottom: 1px;
    margin-left: 32px;
    border-radius: 4px;
    border: none;
    box-shadow: -1px 6px 17px 3px #000;
}
.title_esti_modal{
  position: absolute;
  top: 20px;
  font-size: 18px;
  text-decoration:underline;

  margin-left: 55px;
}

.orpur_esti{
  position: absolute;
  left: 30%;
  bottom: 42px;
}
.close__pagplayer{
  cursor: pointer;
  background: none;
  border: none;
  text-shadow: 1px 2px black;
  padding-right: 1px;
  position: absolute;
  color: red;
  left: 96%;
  top: 1%;
  z-index: 5;
}

.btn_show:hover{
  background-color: #2fdb04;
  box-shadow: 1px 2px black;
}

.modalesti {
  height: 513px;
  margin-top: -16px;
  position: absolute;
  width: 372px;
}

.likeur{
  display: flex;
  font-size: 11px;
  flex-direction: row;
  align-items: center;
}

.picture_sell{
  z-index: 8;
  position: absolute;
  top: 5px;
  width: auto;
}

.close_picture{
  position: absolute;
    right: 3px;
    top: 3px;
    color: red;
}
.Petskill{
  display: flex;
    height: 350px;
    flex-direction: column;
    justify-content: space-evenly;
}

.petSkill{
  display: flex;
  height: 350px;
  flex-direction: column;
  justify-content: space-evenly;
}

.Modal_prevEsti{
  position: absolute;
  width: 450px;
  display: flex;
  border-radius: 5px;
  font-size: 8px;
  top: 515px;
  background: #bf791e;
  height: 118px;
  left: -216px;
  justify-content: space-around;
}

#stop{
  font-size: 75px;
  color:red;
  font-weight: bold;
    margin: 30px;
    display: flex;
    align-items: center;
}
#stop2{
  font-size: 24px;
  color:red;
  font-weight: bold;
    display: flex;
    align-items: center;
}
.color_blue{
  color: green;
  margin: 5px;
  font-size: 22px;
}
.color_red{
  color:red;
  margin: 5px;
  font-size: 22px;
}

.oneline{
  display: flex;
  align-items: baseline;
}
.Esti_carac{

  font-size: 19px;
}
.pagination{
  position: absolute;
  width: 75%;
  display: flex;
  margin: 10px;
  flex-direction: row;

  justify-content: space-around;
}

.pagination button {
  border-radius: 48% 52% 45% 55% / 76% 16% 84% 24%;
  float: left;
  margin-top: 30px;
  padding: 8px 16px;
  text-decoration: none;
  background-image: linear-gradient(45deg,#3023AE 0%,#FF0099 100%);
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  color: white;
  cursor:pointer;
}

.pagination button.active {
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
}
.pagination button:hover:not(.active) {background-color: #ffffff;color: #ffffffc4  ; background-image: linear-gradient(45deg,#221977 0%,#b1006a 100%);}
.Msg_pse{
  margin-left: 30px;
}
.tableSell{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  width: 85%;
  margin-top: 107px;
  min-width: 1000px;
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  border-radius: 9px;
  box-sizing: content-box;
  font-size: 20px;
  margin-left: 235px;
}
.msg_gestion{
  align-items: center;
  display: flex;
  min-width: 400px;
  z-index: -2;
}
#stop{
  margin:0;
}
.reportbig{
  background: #ff000026;
}
body #navrep {
  margin-bottom: 0px;
  color: orange;
}
body #navrep:hover {
  background-color: #bbb815a4;
}
.titl_rep{
  font-size: 50px;
}
.Report_bug{
  display: flex;
  height: 801px;
  margin-left: 200px;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}
.textrep{
  height: 237px;
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  text-align: center;
  align-items: center;
}
.bug-report-form-container{
  display: flex;
  flex-direction: column;
}
.bug-report-form-container form {
  display: flex;
  flex-direction: column;
}

.bug-report-form-container label {
  margin-top: 10px;
}

.bug-report-form-container input,
.bug-report-form-container textarea {
  margin-bottom: 10px;
  padding: 5px;
}

.bug-report-form-container button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.bug-report-form-container h2{
text-decoration-line: underline;
}

.bug-report-form-container button:hover {
  background-color: #0056b3;
}
#stip{
  font-size: 55px;
  color: yellow;
  font-weight: bold;
  margin: 30px;
  display: flex;
  align-items: center;
}

.Trade_sell{
  display:flex;
  font-size: 13px;
}
.btn_trade_sell{
background: none;
border : none;
}

.red{
  color: red;
}

.blue{
  color:greenyellow
}

.présentation_site {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  margin-top: 5px;
  color:#CCCCCC;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.label_form{
  font-size: 20px;
}
.unpre {
  color: rgba(255,255,255, 0.89); /* Couleur bleue */
}

.deuxpre {
  color: rgba(255,255,255, 0.75); /* Couleur verte */
}

.troispre {
  color: rgba(255,255,255, 0.60); /* Couleur rouge */
}

.quapre {
  color: rgba(255,255,255, 0.50); /* Couleur violette */
}
.buy_goldnos{
  height: 151px;
  margin-left: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.tablegold{
  margin-top: 50px;
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat,
            radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat,
            radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat,
            radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat,
            linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat,
            linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat,
            linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
border-radius: 9px;
box-sizing: content-box;
}
thead{
  background: #7575754d;
}
.form_sell{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 180px;
}

.form_sellnd{
  background: radial-gradient(circle at 100% 100%, #131416 0, #131416 6px, transparent 6px) 0% 0%/9px 9px no-repeat, radial-gradient(circle at 0 100%, #131416 0, #131416 6px, transparent 6px) 100% 0%/9px 9px no-repeat, radial-gradient(circle at 100% 0, #131416 0, #131416 6px, transparent 6px) 0% 100%/9px 9px no-repeat, radial-gradient(circle at 0 0, #131416 0, #131416 6px, transparent 6px) 100% 100%/9px 9px no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 6px) calc(100% - 18px) no-repeat, linear-gradient(#131416, #131416) 50% 50%/calc(100% - 18px) calc(100% - 6px) no-repeat, linear-gradient(311deg, #ff0099 0%, #3023AE 100%);
  display: flex;
  width: 840px;
  font-size: 20px;
  height: 600px;
  justify-content: center;
  border-radius: 8px;
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
}
.sell_1{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.sell_2{
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  justify-content: space-around;
  align-items: center;
}
.text_info{
  font-size: 16px;
  margin-top: 15px;
  color: #d5d518;
}
.form_sell>h1{
  margin-bottom: 20px;
  text-decoration: underline;
}
.paiementnd{
  font-size: 30px;
  font-weight: bold;
  text-decoration: underline;
}
.pricends{
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.pay_info{
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: space-evenly;
}
.text_succes{
  color: green;
    font-weight: bold;
}

.text_errir{
  color: red;
    font-weight: bold;
}

.lign_gold{
  margin-top: 20px;
}
.center{
  display: flex;
    flex-direction: column;
    align-items: center;
}
.itemooop{
  margin-top: 15px;
  color: #3fff6a;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.fb_link{
  text-decoration: none;
}

.annul_annonce{
  background-color: orange !important;
}
.confirme_annonce:hover{
  border: none;
  color: #1a1a1a;
  background-color: #0355ad !important;
  font-size: 18px;
    transition: ease 0.5s;

}
.annul_annonce:hover{
  border: none;
  color: #1a1a1a;
font-size: 18px;
  background-color: rgb(187, 121, 0) !important;
    transition: ease 0.5s;

}



.messagepri:hover{
  background-color: #003b7a  !important;
}
.submit_msgsel:hover{
  background-color: #00458f  !important;
  cursor: pointer;
}

#close-crosshair-button:hover{
  cursor: pointer;
}

.innputor>input{
  border: none;
  outline: none;
  border-radius: 15px;
  padding: 5px;
  background-color: #ccc;
  box-shadow: inset 2px 5px 10px rgba(0,0,0,0.3);
  transition: 300ms ease-in-out;
}

.card_left>input{
  background: #9d9d9d57;
    border-radius: 5px;
    color: white;
}


.atqpet{
  background: #9d9d9d57;
  border-radius: 4px;
  width: 50px;
  color: white;
  text-align: center;
}
.card_right>label{
  margin-bottom: -5px;
  font-size: 15px;
}
.defpet{
  background: #9d9d9d57;
  width: 50px;
  color: white;
  border-radius: 4px;
  text-align: center;
}

.upinp {
  display: flex;
  font-size: 15px;
  flex-direction: row;
  align-items: stretch;
}
.raritynp{
  display: flex;
  font-size: 15px;
  flex-direction: row;
  align-items: baseline;
}

.amelionp{
  display: flex;
  font-size: 15px;
  flex-direction: row;
  align-items: baseline;
}

.inputvalu{
  text-align: center;
}

.reseq{
  display: flex;
  justify-content: center !important;
  font-size: 20px;
}
.resvalue{
  background: #9d9d9d57;
  width: 50px;
  color: white;
  text-align: center;
  border-radius: 5px;
}

.quantiT{
  display: flex;
    font-size: 15px;
}

.quantitu{
  width: 55px !important;
  text-align: center;
  border: none;
  outline: none;
  color: black;
  border-radius: 15px;
  padding: 5px;
  background-color: #ccc;
  box-shadow: inset 2px 5px 10px rgba(0,0,0,0.3);
  transition: 300ms ease-in-out;
}

.amelioteur{
  display: flex;
  font-size: 15px;
}

.caracSp{
  display: flex;
  font-size: 15px;
  justify-content: center !important;
}
.caracSp>input{
  background: #9d9d9d57;
  width: 50px !important;
  color: white;
  text-align: center;
  border-radius: 5px;
}

.btn_rune{
  background: none;
    color: lime;
    border: none;
    margin-left: 10px;
    FONT-WEIGHT: BOLD;
}

.btn-class-name {
  --color: 180,255, 100;
  border-radius: .5em;
  transition: .3s;
  background-color: rgba(var(--color), .2);
  color: rgb(var(--color));
  fill: rgb(var(--color));
  font-family: monospace;
  font-weight: bolder;
  font-size: x-large;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  border: 2px solid rgb(var(--color));
  box-shadow: 0 0 10px rgba(var(--color), .4);
  outline: none;
  display: flex;
  align-items: center;
  padding: .5em 1em;
}

.btn-class-name:hover {
  box-shadow: 0 0 0 5px rgba(var(--color), .5);
}

.btn-class-name span {
  transform: scale(.8);
  transition: .3s;
}

.btn-class-name:hover span {
  transform: scale(1);
}

.btn-class-name svg {
  font-size: 0;
  transform: scale(0.5) translateX(0%) rotate(-180deg);
  transition: .3s;
}

.btn-class-name:hover svg {
  font-size: 20px;
  transform: scale(1) translateX(20%) rotate(0deg);
}

.btn-class-name:active {
  transition: 0s;
  box-shadow: 0 0 0 5px rgb(var(--color));
}

.input {
  color: white;
  max-width: 250px;
  height: 44px;
  background-color: #05060f0a;
  border-radius: .5rem;
  padding: 0 1rem;
  border: 2px solid #FFFFFF;
  font-size: 1rem;
  transition: border-color .3s cubic-bezier(.25,.01,.25,1) 0s, color .3s cubic-bezier(.25,.01,.25,1) 0s,background .2s cubic-bezier(.25,.01,.25,1) 0s;
}

.label {
  display: block;
  margin-bottom: .3rem;
  font-size: .9rem;
  font-weight: 400;
  color: #FFFFFF;
  transition: color .3s cubic-bezier(.25,.01,.25,1) 0s;
}

.input:hover, .input:focus, .input-group:hover .input {
  outline: none;
  border-color: #e3e3e3;
}

.input-group:hover .label, .input:focus {
  color: #e3e3e3;
}

.input-group{
  margin-bottom: 5px;
}

.detail {
  display: inline-block;
  border-radius: 8px;
  background-color: #067083;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  padding: 10px;
  width: 130px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
 }
 
 .detail span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
 }
 
 .detail span:after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
 }
 
 .detail:hover span {
  padding-right: 15px;
 }
 
 .detail:hover span:after {
  opacity: 1;
  right: 0;
 }

 .btn_post {
  width: 130px;
  height: 40px;
  font-size: 1.1em;
  cursor: pointer;
  background-color: #171717;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: all .4s;
 }
 
 .btn_post:hover {
  border-radius: 5px;
  transform: translateY(-10px);
  box-shadow: 0 7px 0 -2px #f85959,
   0 15px 0 -4px #39a2db,
   0 16px 10px -3px #39a2db;
 }
 
 .btn_post:active {
  transition: all 0.2s;
  transform: translateY(-5px);
  box-shadow: 0 2px 0 -2px #f85959,
   0 8px 0 -4px #39a2db,
   0 12px 10px -3px #39a2db;
 }

 
.like-dislike-container {
  --dark-grey: #353535;
  --middle-grey: #767676;
  --lightest-grey: linear-gradient(#ffffff,#ffffff);
  --shadow: 0 5px 15px 0 #00000026;
  --shadow-active: 0 5px 5px 0 #00000026;
  --border-radius-main: 10px;
  --border-radius-icon: 50px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  cursor: default;
  color: var(--dark-grey);
  opacity: .9;
  margin: auto;
  padding: 1.5rem;
  font-weight: 600;
  background: var(--lightest-grey);
  max-width: max-content;
  border-radius: var(--border-radius-main);
  box-shadow: var(--shadow);
  transition: .2s ease all;
}

.like-dislike-container:hover {
  box-shadow: var(--shadow-active);
}

.like-dislike-container .tool-box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  top: 0;
  right: 0;
  border-radius: var(--border-radius-main);
}

.like-dislike-container .btn-close {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: .8rem;
  height: .8rem;
  color: transparent;
  font-size: 0;
  cursor: pointer;
  background-color: #ff000080;
  border: none;
  border-radius: var(--border-radius-main);
  transition: .2s ease all;
}

.like-dislike-container .btn-close:hover {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  color: #ffffff;
  background-color: #ff0000cc;
  box-shadow: var(--shadow-active);
}

.like-dislike-container .btn-close:active {
  width: .9rem;
  height: .9rem;
  font-size: .9rem;
  color: #ffffffde;
  --shadow-btn-close: 0 3px 3px 0 #00000026;
  box-shadow: var(--shadow-btn-close);
}

.like-dislike-container .text-content {
  margin-bottom: 1rem;
  font-size: 18px;
  line-height: 1.6;
  cursor: default;
}

.like-dislike-container .icons-box {
  display: flex;
}

.like-dislike-container .icons {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .6;
  margin: 0 0.5rem;
  cursor: pointer;
  user-select: none;
  border: 1px solid var(--middle-grey);
  border-radius: var(--border-radius-icon);
  transition: .2s ease all;
}

.like-dislike-container .icons:hover {
  opacity: .9;
  box-shadow: var(--shadow);
}

.like-dislike-container .icons:active {
  opacity: .9;
  box-shadow: var(--shadow-active);
}

.like-dislike-container .icons .btn-label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  cursor: pointer;
  position: relative;
}

.like-dislike-container .like-text-content {
  border-right: 0.1rem solid var(--dark-grey);
  padding: 0 0.6rem 0 0.5rem;
  pointer-events: none;
}

.like-dislike-container .dislike-text-content {
  border-left: 0.1rem solid var(--dark-grey);
  padding: 0 0.5rem 0 0.6rem;
  pointer-events: none;
}

.like-dislike-container .icons .svgs {
  width: 1.3rem;
  fill: #000000;
  box-sizing: content-box;
  padding: 10px 10px;
  transition: .2s ease all;
}

/* Hide the default checkbox */
.like-dislike-container .icons .input-box {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#icon-like-regular{
  fill: #0050d7;
}
#icon-dislike-regular{
  fill: #d70000;
}
.like-dislike-container .icons #icon-like-regular {
  display: block;
}

.like-dislike-container .icons #icon-like-solid {
  display: none;
}

.like-dislike-container .icons:hover :is(#icon-like-solid, #icon-like-regular) {
  animation: rotate-icon-like 0.7s ease-in-out both;
}

.like-dislike-container .icons #like-checkbox:checked ~ #icon-like-regular {
  display: none;
  animation: checked-icon-like 0.5s;
}

.like-dislike-container .icons #like-checkbox:checked ~ #icon-like-solid {
  display: block;
  animation: checked-icon-like 0.5s;
}

.like-dislike-container .icons #icon-dislike-regular {
  display: block;
  transform: rotate(180deg);
}

.like-dislike-container .icons #icon-dislike-solid {
  display: none;
  transform: rotate(180deg);
}

.like-dislike-container .icons:hover :is(#icon-dislike-solid, #icon-dislike-regular) {
  animation: rotate-icon-dislike 0.7s ease-in-out both;
}

.like-dislike-container .icons #dislike-checkbox:checked ~ #icon-dislike-regular {
  display: none;
  animation: checked-icon-dislike 0.5s;
}

.like-dislike-container .icons #dislike-checkbox:checked ~ #icon-dislike-solid {
  display: block;
  animation: checked-icon-dislike 0.5s;
}

.like-dislike-container .icons .fireworks {
  transform: scale(0.4);
}

.like-dislike-container .icons #like-checkbox:checked ~ .fireworks > .checked-like-fx {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 40px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  animation: 1s fireworks-bang ease-out forwards, 1s fireworks-gravity ease-in forwards, 5s fireworks-position linear forwards;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

.like-dislike-container .icons #dislike-checkbox:checked ~ .fireworks > .checked-dislike-fx {
  position: absolute;
  width: 10px;
  height: 10px;
  left: 40px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  animation: 1s fireworks-bang ease-out forwards, 1s fireworks-gravity ease-in forwards, 5s fireworks-position linear forwards;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

/* Shake Animation */
@keyframes rotate-icon-like {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

@keyframes rotate-icon-dislike {
  0% {
    transform: rotate(180deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(183deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(177deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(181deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(180deg) translate3d(0, 0, 0);
  }
}

/* Checked Animation */
@keyframes checked-icon-like {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1.2) rotate(-10deg);
  }
}

@keyframes checked-icon-dislike {
  0% {
    transform: scale(0) rotate(180deg);
    opacity: 0;
  }

  50% {
    transform: scale(1.2) rotate(170deg);
  }
}

/* Fireworks Animation */
@keyframes fireworks-position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@keyframes fireworks-gravity {
  to {
    transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes fireworks-bang {
  to {
    box-shadow: 114px -107.3333333333px #8800ff, 212px -166.3333333333px #a600ff, 197px -6.3333333333px #ff006a, 179px -329.3333333333px #3300ff, -167px -262.3333333333px #ff0062, 233px 65.6666666667px #ff008c, 81px 42.6666666667px #0051ff, -13px 54.6666666667px #00ff2b, -60px -183.3333333333px #0900ff, 127px -259.3333333333px #ff00e6, 117px -122.3333333333px #00b7ff, 95px 20.6666666667px #ff8000, 115px 1.6666666667px #0004ff, -160px -328.3333333333px #00ff40, 69px -242.3333333333px #000dff, -208px -230.3333333333px #ff0400, 30px -15.3333333333px #e6ff00, 235px -15.3333333333px #fb00ff, 80px -232.3333333333px #d5ff00, 175px -173.3333333333px #00ff3c, -187px -176.3333333333px #aaff00, 4px 26.6666666667px #ff6f00, 227px -106.3333333333px #ff0099, 119px 17.6666666667px #00ffd5, -102px 4.6666666667px #ff0088, -16px -4.3333333333px #00fff7, -201px -310.3333333333px #00ffdd, 64px -181.3333333333px #f700ff, -234px -15.3333333333px #00fffb, -184px -263.3333333333px #aa00ff, 96px -303.3333333333px #0037ff, -139px 10.6666666667px #0026ff, 25px -205.3333333333px #00ff2b, -129px -322.3333333333px #40ff00, -235px -187.3333333333px #26ff00, -136px -237.3333333333px #0091ff, -82px -321.3333333333px #6a00ff, 7px -267.3333333333px #ff00c8, -155px 30.6666666667px #0059ff, -85px -73.3333333333px #6a00ff, 60px -199.3333333333px #55ff00, -9px -289.3333333333px #00ffaa, -208px -167.3333333333px #00ff80, -13px -299.3333333333px #ff0004, 179px -164.3333333333px #ff0044, -112px 12.6666666667px #0051ff, -209px -125.3333333333px #ff00bb, 14px -101.3333333333px #00ff95, -184px -292.3333333333px #ff0099, -26px -168.3333333333px #09ff00, 129px -67.3333333333px #0084ff, -17px -23.3333333333px #0059ff, 129px 34.6666666667px #7300ff, 35px -24.3333333333px #ffd900, -12px -297.3333333333px #ff8400, 129px -156.3333333333px #0dff00, 157px -29.3333333333px #1a00ff, -221px 6.6666666667px #ff0062, 0px -311.3333333333px #ff006a, 155px 50.6666666667px #00ffaa, -71px -318.3333333333px #0073ff;
  }
}

.radio-input_acnt {
  display: flex;
  flex-direction: row;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.radio-input_acnt input[type="radio"] {
  display: none;
}

.radio-input_acnt label {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #212121;
  border-radius: 5px;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.radio-input_acnt label:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ccc;
  transition: all 0.3s ease-in-out;
}

.radio-input_acnt input[type="radio"]:checked + label:before {
  background-color: green;
  top: 0;
}

.radio-input_acnt input[type="radio"]:checked + label {
  background-color: royalblue;
  color: #fff;
  border-color: rgb(129, 235, 129);
  animation: radio-translate 0.5s ease-in-out;
}

@keyframes radio-translate {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

.div_lvl{
  display: flex;
    width: 99%;
    justify-content: space-between;
}
#description_accountss{
    width: 350px;
    height: 150px;
    text-align: start;
    vertical-align: top;
    resize: none;
  }

  .bot_go{
    display: flex;
    align-items: center;
    flex-direction: row;
  }

.selected-image-container{
  width: 100px;
  display: flex;
  margin: 25px;
  flex-direction: column;
  align-items: center;
}

.img_account {
  display: flex;
  align-items: flex-end;
}
  

.buttonis {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  border: 0px solid transparent;
  background-color: rgba(100,77,237,0.08);
  border-radius: 1.25em;
  transition: all 0.2s linear;
 }
 
 .buttonis:hover {
  box-shadow: 3.4px 2.5px 4.9px rgba(0, 0, 0, 0.025),
   8.6px 6.3px 12.4px rgba(0, 0, 0, 0.035),
   17.5px 12.8px 25.3px rgba(0, 0, 0, 0.045),
   36.1px 26.3px 52.2px rgba(0, 0, 0, 0.055),
   99px 72px 143px rgba(0, 0, 0, 0.08);
 }
 
 .tooltip {
  position: relative;
  display: inline-block;
 }
 
 .tooltip .tooltiptext {
  visibility: hidden;
  width: 4em;
  background-color: rgba(0, 0, 0, 0.253);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 110%;
 }
 
 .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.253) transparent transparent;
 }
 
 .tooltip:hover .tooltiptext {
  visibility: visible;
 }

 .acnt_div{
  margin-left: 139px;
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    align-items: center;
 }

 .select_btn{
  width: 50%;
  margin-top: 25px;
  display: flex;
  justify-content: space-around;}